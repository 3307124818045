import { Location } from '../types/lab-test/index';
import querystring from 'qs';
import parser from 'ua-parser-js';
import { API_BASE_URL, SITE_MAP_API, refPartnerApiStorageName, userStorageName } from './config';
import { convertFileToBase64, objectToQueryString } from '../utils/cmnHelper';

type BodyType = object | string | FormData | Record<string, string> | string[][] | URLSearchParams | undefined;
type HeaderType = object | undefined;
type PromiseType = Response | Error | any;
interface RequestOptions {
    endpoint: string;
    includeGeneral?: boolean;
    method?: string;
    body?: BodyType;
    headers?: HeaderType;
    source?: { token: AbortSignal } | null;
    user?: { authToken: string };
}
interface ParsedAgent {
    browser?: {
        name?: string;
        version?: string;
    };
    os?: {
        name?: string;
        version?: string;
    };
}
type bseHomeData = {
    prevBrowseItem?: boolean;
    appHomeCategory?: boolean;
    appHealthHome?: boolean;
    appBeautyHome?: boolean;
    appTopBrand?: boolean;
    blogRegion?: boolean;
    page?: number;
    _perPage?: number;
};

interface uploadPrescription {
    files: any;
    addToCart?: number;
}

export const isValidJSON = (data: string | null) => {
    if (data) {
        try {
            JSON.parse(data);
            return true;
        } catch (error) {
            return false;
        }
    }
};

const getUserFromLocalStorage = () => {
    const getData = typeof window !== 'undefined' ? localStorage.getItem(userStorageName) : null;
    return getData && isValidJSON(getData) ? JSON?.parse(getData) : {};
};

const agentChecker = (userAgentVar: string): ParsedAgent => {
    const { browser, os } = parser(userAgentVar);
    return { os, browser };
};
const getRefPartnerRefFromLocalStorage: any = () => {
    const getData = typeof window !== 'undefined' ? localStorage.getItem(refPartnerApiStorageName) : null;
    return getData && isValidJSON(getData) ? JSON?.parse(getData) : {};
};
const getUrl = (endpoint: string, userAgentVar?: string, includeGeneral: boolean = true): string => {
    const { os, browser } = agentChecker(userAgentVar || '');
    const extra = querystring.stringify({
        f: ['iPhone', 'iPod', 'iPad', 'Android', 'BlackBerry', 'Mobile'].includes(os?.name || '') ? 'mweb' : 'mweb',
        b: browser?.name || '',
        v: browser?.version || '',
        os: os?.name || '',
        osv: os?.version || '',
        ...(getRefPartnerRefFromLocalStorage() ? { refPartner: getRefPartnerRefFromLocalStorage().refPartner } : {})
    });
    if (includeGeneral) {
        return `${API_BASE_URL}/general/${endpoint}${endpoint.includes('?') ? '&' : '?'}${extra}`;
    }
    return `${API_BASE_URL}/${endpoint}${endpoint.includes('?') ? '&' : '?'}${extra}`;
};

const handleResponse = async ({ endpoint, method, body, headers, user }: RequestOptions): Promise<PromiseType> => {
    if (!user?.authToken) {
        console.log('Missing authentication token!');
    }

    try {
        const refreshTokenResponse = await fetch(`${API_BASE_URL}/auth/v1/token/refresh/`, {
            method: 'POST',
            credentials: 'include'
        });

        const refreshTokenData = await refreshTokenResponse.json();

        if (refreshTokenData.status === 'success') {
            const newAuthToken = refreshTokenData?.data?.authToken;
            const updatedUser = { ...user, authToken: newAuthToken };

            await new Promise<void>((resolve) => {
                localStorage.setItem(userStorageName, JSON.stringify(updatedUser));
                resolve();
            });

            return await request({
                endpoint,
                method,
                body,
                headers
            });
        } else {
            localStorage.removeItem(userStorageName);
        }
        console.log('Refresh Token Error ----->', refreshTokenData);
    } catch (error) {
        console.log('Handle response error ----->', error);
    }
};

export const request = async ({
    endpoint,
    includeGeneral,
    method = 'GET',
    body,
    headers = {},
    source = null
}: RequestOptions): Promise<PromiseType> => {
    const accessToken = getUserFromLocalStorage()?.authToken || null;
    const authHeaders: Record<string, string> = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    const url = getUrl(endpoint, '', includeGeneral);

    try {
        const requestBody = body
            ? typeof window !== 'undefined' && body instanceof FormData
                ? body
                : new URLSearchParams(body instanceof Object ? Object.entries(body) : body)
            : undefined;

        const fetchOptions: RequestInit = {
            method,
            headers: {
                ...headers,
                ...authHeaders
            },

            //TODO: Dynamic Revalidate
            next: { revalidate: 60 * 10 },
            credentials: 'include',
            signal: source?.token
        };

        if (body && method !== 'GET') {
            fetchOptions.body = requestBody;
        }

        const response = await fetch(url, fetchOptions);

        if (!response.ok) {
            if (response.status === 401) {
                return handleResponse({ endpoint, method, body, headers, user: getUserFromLocalStorage() });
            } else if (response.status === 503) {
                if (typeof window !== 'undefined') {
                    window.location.href = '/maintanance';
                }
            }
            console.log('Url ----->', url);
            console.log('Response ----->', response);
        }
        return await response.json();
    } catch (error) {
        console.error('Error url --->>>', url);
    }
};

export const bseHomeData = (
    queryString: bseHomeData = {
        prevBrowseItem: false,
        appHomeCategory: false,
        appHealthHome: false,
        appTopBrand: false,
        appBeautyHome: false,
        blogRegion: false,
        page: 1,
        _perPage: 10
    }
) => {
    const generateUrl = () => {
        const base = `v3/blocks?_type=app&_page=${queryString?.page}&_perPage=10`;

        if (queryString?.prevBrowseItem) {
            return `${base}&_block=previously_browsed_items`;
        } else if (queryString?.appHomeCategory) {
            return `v3/blocks?_type=app&_block=category&_page=1&_perPage=100`;
        } else if (queryString?.appHealthHome) {
            return `${base}&_region=app_health_care_home&_allow_sales=1&_is_base=1`;
        } else if (queryString?.appBeautyHome) {
            return `${base}&_region=app_beauty_home&_allow_sales=1&_is_base=1`;
        } else if (queryString?.appTopBrand) {
            return `${base}&_block=app_medicine_top_brand`;
        } else if (queryString?.blogRegion) {
            return `${base}&_region=website_blog`;
        } else {
            //featured_brands_product_type=medicine ->>>>> for sorting only specific product type brand;
            return `${base}&_region=app_medicine_home&_is_base=1&_allow_sales=1`;
        }
    };
    const url = generateUrl();

    if (queryString?.page || queryString?.appHomeCategory) {
        return request({
            endpoint: url
        });
    }
};

export const bseBrandData = (id: string) => {
    const url = `v3/blocks?_type=app&_page=1&_perPage=100&_region=brand_page&brand_best_selling_products_brand_id=${id}&brand_newly_launched_items_brand_id=${id}&brand_all_products_brand_id=${id}&_allow_sales=1&_is_base=1`;
    return request({
        endpoint: url
    });
};

export const bseBlocksByMachineName = (queryParams: any, _page = 1, _perPage = 10) => {
    const url = `v3/blocks?_type=app&_page=${_page}&_perPage=${_perPage ? _perPage : 10}&${objectToQueryString(queryParams)}`;
    return request({
        endpoint: url
    });
};

export const bseSingleProduct = (id: string) => {
    return request({
        endpoint: `v1/product/${id}`
    });
};

//cart
export const bseUserCart = (deliveryOption = 'regular') => {
    return request({
        endpoint: `v1/userCartDetails?_delivery_option=${deliveryOption}&_return_details=1`
    });
};
export const getProductVariantMaxMin = (pv_id: any) => {
    return request({
        endpoint: `v1/productVariantMaxMin/${pv_id}`
    });
};
export const bseUserCartAdd = (data: any) => {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    return request({
        endpoint: `v1/userCart?_return_details=1`,
        method: 'POST',
        body: formData
    });
};
export const bseUserCartRemove = (variantId: number) => {
    return request({
        endpoint: `v1/userCart/${variantId}?_return_details=1`,
        method: 'DELETE'
    });
};
export const removeCartUnavailableProducts = () => {
    return request({
        endpoint: `v1/removeUnavailableProducts`,
        method: 'POST'
    });
};
export const addToFavUnavailableProducts = () => {
    return request({
        endpoint: `v1/addItemsToWishlist`,
        method: 'POST'
    });
};
export const bseUpdateCartLocation = (userLocationId: number) => {
    return request({
        endpoint: `v1/updateCartLocation?_return_details=1`,
        method: 'POST',
        body: {
            uc_user_location_id: userLocationId
        }
    });
};

interface checkoutInitiated {
    _delivery_option: any;
    _shift_schedule_id: any;
}

export const checkoutInitiated = (querystring: checkoutInitiated) => {
    return request({
        endpoint: `v1/checkoutInitiated?${objectToQueryString(querystring)}`
    });
};

export const bseCheckout = (data: any) => {
    return request({
        endpoint: `v1/orderCreateFromCart`,
        method: 'POST',
        body: data
    });
};
export const cartConfirmOrder = (data: any) => {
    const formData = new FormData();
    formData.append('uc_total', data.uc_total);
    formData.append('uc_count', data.uc_count);
    formData.append('po_payment_method', data.po_payment_method);
    formData.append('po_delivery_option', data.po_delivery_option);
    formData.append('po_user_location_id', data.po_user_location_id);
    formData.append('po_delivery_shift_schedule_id', data.po_delivery_shift_schedule_id);
    formData.append('po_subscription_frequency', data.po_subscription_frequency);
    formData.append('po_user_notes', data.po_user_notes);

    data.ucd.forEach((item: any, index: number) => {
        formData.append(`ucd[${index}][ucd_variant_id]`, item.ucd_variant_id);
        formData.append(`ucd[${index}][ucd_qty]`, item.ucd_qty);
    });

    return request({
        endpoint: `v1/confirmOrder`,
        method: 'POST',
        body: formData
    });
};

// export const addBrowseProduct = (id: string | number) => {
//     //products
//     return request({
//         endpoint: `v1/userBrowsedProduct/${id}`,
//         // includeGeneral: false,
//         method: 'POST'
//     });
// };

export const getBrowseProduct = () => {
    return request({
        endpoint: `v3/blocks?_type=app&_region=app_single_page`
    });
};
interface GetSingleProductRegion {
    _page: number;
    _perPage: number;
    frequently_bought_together_product_id: any;
    app_more_from_brand_brand_id: any;
    _exclude_product_variant_id: any;
}
export const getSingleProductRegion = ({
    _page = 1,
    _perPage = 100,
    frequently_bought_together_product_id,
    app_more_from_brand_brand_id,
    _exclude_product_variant_id
}: GetSingleProductRegion) => {
    return request({
        endpoint: `v3/blocks?_type=app&_region=app_single_page_body&_perPage=${_perPage}&_page=${_page}&app_frequently_bought_together_product_id=${frequently_bought_together_product_id}&app_more_from_brand_brand_id=${app_more_from_brand_brand_id}&_exclude_product_variant_id=${_exclude_product_variant_id}&_allow_sales=1`
    });
};

export const getSingleRegion = (queryString: any) => {
    const generateUrl = () => {
        const base = `v3/blocks?_type=app`;
        return `${base}&${objectToQueryString(queryString)}`;
    };
    const url = generateUrl();

    return request({
        endpoint: url
    });
};

export const getSinglePageSimilarProduct = ({
    _page = 1,
    _perPage = 20,
    _g_product_id,
    app_similar_products_product_variant_id,
    _exclude_product_variant_id
}: any) => {
    return request({
        endpoint: `v3/blocks?_type=app&_region=app_single_page_top&_perPage=${_perPage}&_page=${_page}&_g_product_id=${_g_product_id}&app_similar_products_product_variant_id=${app_similar_products_product_variant_id}&_exclude_product_variant_id=${_exclude_product_variant_id}&_allow_sales=1&_is_base=1`
    });
};

export const getAlterNativeBrands = (id: string) => {
    return request({
        endpoint: `v3/blocks?_block=alternative_brands_for&_g_product_id=${id}`
    });
};

export const getCompany = (id: string) => {
    return request({
        endpoint: `v1/productBrand/${id}`
    });
};

interface TypeCompanySearch {
    _page?: number;
    _perPage?: number;
    _search?: string;
    _name?: string;
    filters?: any;
}
export const getCompanyAll = ({ _page = 1, _perPage = 21, _search = '', filters }: TypeCompanySearch = {}) => {
    const url = filters
        ? `v1/productBrand?_page=${_page}&_perPage=${_perPage}&_search=${_search}&${objectToQueryString(filters)}`
        : `v1/productBrand?_page=${_page}&_perPage=${_perPage}&_search=${_search}`;
    return request({
        endpoint: url
    });
};

export const getCompanyByName = ({ _page = 1, _perPage = 100, _name = '' }: TypeCompanySearch = {}) => {
    return request({
        endpoint: `v1/productBrand?_page=${_page}&_perPage=${_perPage}&_name=${_name}`
    });
};

// coupon
export const checkCouponEligibility = (body: any) => {
    return request({
        endpoint: `v1/checkCouponEligibility?_return_details=1&_delivery_option=regular`,
        method: 'POST',
        body: body
    });
};
export const couponDelete = (option: any) => {
    return request({
        endpoint: `v1/deleteCoupon?_return_details=1&_delivery_option=${option}`,
        method: 'POST'
    });
};

// prescription
export const getPrescriptionList = (_page = 1) => {
    return request({
        endpoint: `v1/userPrescription?_page=${_page}&_perPage=10`
    });
};
export const delPrescriptionList = (up_id: string) => {
    return request({
        endpoint: `v1/userPrescription/?up_ids=${up_id}`,
        method: 'DELETE'
    });
};

export const uploadPrescription = async ({ files, addToCart = 0 }: uploadPrescription) => {
    const formData = new FormData();
    await Promise.all(
        Array.from(files).map(async (file: any, index) => {
            const base64String: any = await convertFileToBase64(file);
            formData.append(`attachedFiles_up_s3key[${index}][title]`, file.name);
            formData.append(`attachedFiles_up_s3key[${index}][src]`, base64String);
        })
    );
    return request({
        endpoint: `v1/userPrescription?addToCart=${addToCart}`,
        method: 'POST',
        body: formData
    });
};
export const userPrescriptionCart = async () => {
    return request({
        endpoint: `v1/getUserCartPrescriptions`
    });
};
export const addPrescriptionToCart = async (ids: any[]) => {
    const formData = new FormData();

    ids.forEach((id: any, index: any) => {
        formData.append(`up_id[${index}]`, id.toString());
    });

    return request({
        endpoint: `v1/addPrescriptionToCart`,
        method: 'POST',
        body: formData
    });
};
export const delPrescriptionFromCart = async (prescriptionKey: any) => {
    return request({
        endpoint: `v1/deletePrescriptionFromCart?prescriptionKey=${prescriptionKey}`,
        method: 'DELETE'
    });
};

//my order - user
export const myOrders = (_po_status = 'all') => {
    const endpoint = _po_status === 'all' ? 'v1/productOrder' : `v1/productOrder?_po_status=${_po_status}`;
    return request({
        endpoint
    });
};
export const getSingleOrder = (orderId: string) => {
    return request({
        endpoint: `v1/productOrder/${orderId}`
    });
};
export const cancelOrder = (orderId: string, _reason: string) => {
    return request({
        endpoint: `v1/cancelOrder/${orderId}?_reason=${_reason}`,
        method: 'POST'
    });
};
export const getCancelReason = () => {
    const params = {
        _order: 'ASC',
        _orderBy: 't_weight',
        _page: 1,
        _perPage: 25,
        _v_id: 40
    };
    return request({
        endpoint: `v1/taxonomy?${objectToQueryString(params)}`
    });
};
export const reOrder = (orderId: string) => {
    return request({
        endpoint: `v1/reorder/${orderId}`,
        method: 'POST'
    });
};
interface OrderReplace {
    existing_poi_id: any;
    poi_product_id?: any;
    poi_product_variant_id?: any;
    poi_product_unit_id?: any;
    poi_product_qty?: any;
}
export const orderReplaceDelete = (orderId: string, poiData: OrderReplace) => {
    return request({
        endpoint: `v1/productOrder/${orderId}`,
        method: 'POST',
        body: { poi: JSON.stringify(poiData) }
    });
};

//wishlist
export const getWishListCount = (variantId: any) => {
    //products
    return request({
        endpoint: `v1/globalSingleProductAction/${variantId}`,
        // includeGeneral: false,
        method: 'POST'
    });
};
export const wishListToggle = (variantId: number | string) => {
    return request({
        endpoint: `v1/product-wishlist/toggle/${variantId}?_return_details=1`,
        includeGeneral: false,
        method: 'POST'
    });
};
export const getUserWishList = (tags = []) => {
    return request({
        endpoint: `v1/product-wishlist/user/?tags=${tags}`,
        includeGeneral: false
    });
};
export const getUserWishLisTags = () => {
    return request({
        endpoint: `v1/product-wishlist-tags`,
        includeGeneral: false
    });
};
export const createUserWishlistTags = (body: any) => {
    return request({
        endpoint: `v1/product-wishlist-tags`,
        includeGeneral: false,
        method: 'POST',
        body: body
    });
};
export const deleteUserWishlistTags = (id: any) => {
    return request({
        endpoint: `v1/product-wishlist-tags/${id}`,
        includeGeneral: false,
        method: 'DELETE'
    });
};

// meili search
interface TypeMeiliSearch {
    _meilisearch?: number;
    page?: number;
    _perPage?: number;
    _search?: string;
    blockProducts?: any;
    _haveImage?: 0 | 1;
    trending_product?: boolean;
    filters?: any;
}

export const getMeiliSearch = ({
    _meilisearch = 1,
    page = 1,
    _perPage = 20,
    _search,
    filters,
    _haveImage = 0,
    trending_product = false,
    blockProducts
}: TypeMeiliSearch = {}) => {
    const generateUrl = () => {
        // const base = `v3/search/?_meilisearch=${_meilisearch}&_page=${page}&_perPage=${_perPage}_haveImage=${_haveImage}`;
        const base = `v3/search/?_page=${page}&_perPage=${_perPage}`;
        if (_search) {
            return `${base}&_search=${_search}`;
        } else if (filters && trending_product) {
            // return `v3/search/?_meilisearch=${_meilisearch}&_is_base=1&${objectToQueryString(filters)}`;
            return `v3/search/?${objectToQueryString(filters)}`;
            // return `v3/product/?${objectToQueryString(filters)}`;
        } else if (filters) {
            // return `v3/search/?_meilisearch=${_meilisearch}&_is_base=1&${objectToQueryString(filters)}`;
            return `v3/search/?${objectToQueryString(filters)}`;
        } else {
            return base;
        }
    };

    const url = generateUrl();
    if (url) {
        return request({
            endpoint: url
        });
    }
};

// taxonomy
export const getCategoryByTaxonomy = (endpoint: string, _page = 1, _perPage = 20) => {
    return request({
        endpoint: `v1/taxonomy?${endpoint}&_page=${_page}&_perPage=${_perPage}&_get_product_count=1&_hide_zero_product_count=1`
    });
};
export const getCategoryDataById = (taxonomyId: string) => {
    return request({
        endpoint: `v1/taxonomy/${taxonomyId}?_get_product_count=1&_hide_zero_product_count=1`
    });
};
export const getTaxonomyByParentId = (parentId: string, _page = 1) => {
    return request({
        endpoint: `v1/taxonomy?_parent_id=${parentId}&_v_machine_name=product_category&_fields=t_v_id,t_id,t_parent_id,t_title,t_has_child,t_icon&_orderBy=t_weight&_page=${_page}&perPage=100&_get_product_count=1&_hide_zero_product_count=1`
    });
};
export const getCategoryDataByName = (category: string) => {
    return request({
        endpoint: `v1/taxonomy?_machine_name=${category}&_v_machine_name=product_category&_fields=t_v_id,t_id,t_title,t_banner&_orderBy=t_weight&_get_product_count=1&_hide_zero_product_count=1`
    });
};

//vendor dashboard
export const getVendorDashboard = () => {
    return request({
        endpoint: `procurement/v1/vendorDashboard`,
        includeGeneral: false
    });
};

// Request - Suggestion / Products
// export const requestProducts = (body: any) => {
//     return request({
//         endpoint: `v1/product`,
//         method: 'POST',
//         body
//     });
// };
// export const getSuggestionList = () => {
//     return request({
//         endpoint: `v1/suggestedProduct`
//     });
// };
export const addSuggestProducts = (body: any) => {
    const formData = new FormData();

    for (const key in body) {
        if (key === 'attachedFile' && body[key] instanceof File) {
            formData.append(`attachedFiles_sp_images[0]`, body[key]);
        } else {
            formData.append(key, body[key]);
        }
    }

    return request({
        endpoint: `v1/suggestedProduct`,
        method: 'POST',
        body: formData
    });
};

interface ProductRequestStock {
    prs_product_id: any;
    prs_product_variant_id: any;
}

export const productRequestStock = (body: ProductRequestStock) => {
    return request({
        endpoint: `v1/ProductRequestStock`,
        method: 'POST',
        body
    });
};
export const getAllProductRequestStock = () => {
    return request({
        // endpoint: `v1/ProductRequestStock?_fields=prs_product_variant_id,prs_id&_page=1&_perPage=500`
        endpoint: `v1/productStockRequestIds`
    });
};

//Notifications
export const userNotification = () => {
    return request({
        endpoint: `v1/notification`
    });
};
export const userNotificationById = (notificationId: any) => {
    return request({
        endpoint: `v1/notification/${notificationId}`
    });
};
export const userUpdateNotificationUpdate = (notificationId: any) => {
    return request({
        endpoint: `v1/notificationAction/${notificationId}/notificationAction`,
        method: 'POST'
    });
};
export const userNotificationAllRead = (body: any) => {
    return request({
        endpoint: `v1/notification/multipleRead/`,
        method: 'POST',
        body
    });
};

//Blog
interface GetBlog {
    id?: any;
    _type?: any;
    queryParams?: {
        _page?: number | string;
        _perPage?: number | string;
        _orderBy?: string;
        _order?: string;
    };
}
const defaultQueryParams = {
    _page: 1,
    _perPage: 10,
    _orderBy: 'order',
    _order: 'desc'
};

export const getBlog = ({ id, _type, queryParams = {} }: GetBlog) => {
    const mergedQueryParams = {
        ...defaultQueryParams,
        ...queryParams
    };

    const generateUl = () => {
        if (id) {
            return `v1/blogPost/${id}`;
        } else if (_type) {
            return `v1/blogPost?_type=${_type}&${objectToQueryString(mergedQueryParams)}`;
        } else {
            return `v1/blogPost?${objectToQueryString(mergedQueryParams)}`;
        }
    };

    return request({
        endpoint: generateUl()
    });
};
export const getBlogCategory = () => {
    return request({
        endpoint: `v1/blogCategory`,
        includeGeneral: false
    });
};
export const getBlogComment = (blogId: any, type = 'comment') => {
    return request({
        endpoint: `v1/blogComment?_bc_blog_post_id=${blogId}&_bc_type=${type}`
    });
};
export const relatedItemList = (blogId: any) => {
    return request({
        endpoint: `v1/relatedItemList/?_bp_id=${blogId}`,
        includeGeneral: false
    });
};
export const crateBlogLike = (fromData: any) => {
    return request({
        endpoint: `v1/blogLike`,
        method: 'POST',
        body: fromData
    });
};
export const createBlogComment = (body: any) => {
    return request({
        endpoint: `v1/blogComment`,
        method: 'POST',
        body: body
    });
};

//JOBS
export const getJobList = () => {
    return request({
        endpoint: `jobs/v1`,
        includeGeneral: false
    });
};
export const getJobSingle = (jobId: any) => {
    return request({
        endpoint: `jobs/v1/${jobId}`,
        includeGeneral: false
    });
};
export const applyJobs = (body: any) => {
    const formData = new FormData();

    for (const key in body) {
        if (key === 'attachedFile' && body[key] instanceof File) {
            formData.append(`attachedFile[0]`, body[key]);
        } else {
            formData.append(key, body[key]);
        }
    }

    return request({
        endpoint: `jobs/v1/apply/${body?.j_id}`,
        includeGeneral: false,
        method: 'POST',
        body: formData
    });
};

// OLD API ============
export const getSpecialOffers = (): Promise<any> => request({ endpoint: 'v1/offers', includeGeneral: false, method: 'GET' });

export const postAuthLogout = () => {
    return request({
        endpoint: 'auth/v1/logout',
        method: 'POST',
        includeGeneral: false
    });
};

export const postAuthSMSSend = (mobile: any, fcmToken: any, referral: any) => {
    return request({
        endpoint: 'auth/v1/sms/send',
        method: 'POST',
        includeGeneral: false,
        body: { mobile, fcmToken, referral }
    });
};
export const postAuthSMSVerify = (mobile: any, otp: any, fcmToken: any, referral: any) => {
    return request({
        endpoint: 'auth/v1/sms/verify',
        method: 'POST',
        includeGeneral: false,
        body: {
            mobile,
            otp,
            fcm_token: fcmToken
            // referral
        }
    });
};
export const postAutoLogin = (mobile: any, token: any) => {
    return request({
        endpoint: 'v1/auth/auto-login',
        method: 'POST',
        includeGeneral: false,
        body: {
            mobile: mobile,
            token: token
        }
    });
};

//Req Stock
//Check if needed
// export const requestStock = (m_id: any) => {
//     return request({
//         endpoint: 'v1/requestStock/',
//         method: 'POST',
//         body: {
//             m_id
//         }
//     });
// };

// user profile update
export const userProfileUpdate = (body: any) => {
    const formData = new FormData();
    Object.keys(body).forEach((key) => {
        const value = body[key];
        if (!value) {
            return;
        }
        formData.append(key, value);
    });
    return request({
        endpoint: 'v1/profile',
        method: 'POST',
        includeGeneral: false,
        body: formData
    });
};
export const getUpdateProfile = () => {
    return request({
        endpoint: `v1/profile`,
        includeGeneral: false,
        method: 'GET'
    });
};

//user address
export const allLocations = () => {
    return request({
        endpoint: `v1/allLocations/`,
        includeGeneral: false,
        method: 'GET'
    });
};
export const getUserAddresses = (obj: any) => {
    return request({
        endpoint: `userLocation/v1/?${querystring.stringify(obj)}`,
        includeGeneral: false,
        method: 'GET'
    });
};
export const setCartDefaultAddress = (locationId: any) => {
    return request({
        endpoint: `cart/v1/address`,
        method: 'POST',
        body: {
            ul_id: locationId
        }
    });
};
export const setNewAddress = (addressObj: any) => {
    return request({
        endpoint: `userLocation/v1/`,
        includeGeneral: false,
        method: 'POST',
        body: addressObj
    });
};
export function updateAddress(userLocationId: any, addressObj: any) {
    return request({
        endpoint: `userLocation/v1/${userLocationId}`,
        includeGeneral: false,
        method: 'POST',
        body: addressObj
    });
}
export const deleteAddress = (userLocationId: number) => {
    return request({
        endpoint: `userLocation/v1/${userLocationId}`,
        includeGeneral: false,
        method: 'DELETE'
    });
};

export const getSubArea = (_l_id: any) => {
    return request({
        endpoint: `v1/subArea?_l_id=${_l_id}`
    });
};

//user cash and Refer
export const userAroggaCash = () => {
    return request({
        endpoint: 'v1/cashBalance',
        includeGeneral: false
    });
};

interface userCashWithdraw {
    id: number;
    withdraw_method: string;
    withdraw_mobile?: string | number;
}

export const userCashWithdraw = (body: userCashWithdraw) => {
    return request({
        endpoint: `v1/cashWithdraw/${body?.id}`,
        method: 'POST',
        body: body
    });
};
interface UserReferrer {
    u_referrer: string;
}
export const userReferrer = (data: UserReferrer) => {
    return request({
        endpoint: 'v1/userReferrer',
        method: 'POST',
        includeGeneral: false,
        body: data
    });
};


/*Product Review start*/
export const createReview = (data: any, reviewId?: number) => {
    return request({
        endpoint: `v1/ProductReview${reviewId ? `/${reviewId}` : ''}/`,
        method: 'POST',
        body: data
    });
};

export const fetchToReviews = (searchParams: any) => {
    return request({
        endpoint: `general/v1/toReviews/?${querystring.stringify(searchParams)}`,
        includeGeneral: false,
        method: 'GET'
    });
};

export const fetchMyReviews = (searchParams: any) => {
    return request({
        endpoint: `general/v1/myReviews/?${querystring.stringify(searchParams)}`,
        includeGeneral: false,
        method: 'GET'
    });
};

export const fetchProductReview = (searchParams: any) => {
    return request({
        endpoint: `general/v1/ProductReview/?${querystring.stringify(searchParams)}`,
        includeGeneral: false,
        method: 'GET'
    })
};

export const getReviewSummery = (searchParams: any) => {
    return request({
        endpoint: `general/v1/reviewSummery/?${querystring.stringify(searchParams)}`,
        includeGeneral: false,
        method: 'GET'
    })
};

export const deleteReview = (reviewId: number) => {
    return request({
        endpoint: `general/v1/deleteReview/${reviewId}`,
        includeGeneral: false,
        method: 'POST'
    });
};

export const getProductRatingTaxonomies = () => {
    return request({
        endpoint: `admin/v1/taxonomiesByVocabulary/product_rating/`,
        includeGeneral: false,
        method: 'GET'
    });
};

//Register Pharmacy
export const registerPharmacy = (data: any) => {
    return request({
        endpoint: 'v2/pharmacy',
        method: 'POST',
        body: data
    });
};

// checkout time slots
export const getAllTimeSlots = () => {
    return request({
        endpoint: `v1/timeSlots?_delivery_option=express`,
        includeGeneral: false,
        method: 'GET'
    });
};
export const getPharmacyStatus = () => {
    return request({
        endpoint: 'v1/getPharmacyStatus',
        method: 'GET'
    });
};

// page
export const getFaqHeaders = () => {
    return request({
        endpoint: 'v1/faqsHeaders',
        includeGeneral: false
    });
};
export const getSingleFaqs = (slug: string) => {
    return request({
        endpoint: `v1/faqs/${slug}`,
        includeGeneral: false
    });
};
export const additionalPageContent = (slug: string) => {
    return request({
        endpoint: `v1/page/${slug}`,
        includeGeneral: false
    });
};

//Sitemap
export const getSiteMapCount = () => {
    return request({
        endpoint: `v1/sitemap/count/?api_key=${SITE_MAP_API}`,
        includeGeneral: false
    });
};

export const getSiteMapData = ({ page, perPage }: any) => {
    return request({
        endpoint: `v1/sitemap/data/?_page=${page}&_perPage=${perPage}&api_key=${SITE_MAP_API}`,
        includeGeneral: false
    });
};

// infiniteScroll
export const sameGenericApi = `v1/sameGenericProducts`;
export const getTransactionApi = `v1/userTransaction`;
export const getNotificationApi = `v1/notification`;
export const getProductRequestStock = `v1/ProductRequestStock`;
export const getUserWishlist = `v1/product-wishlist/user`;
export const getSearchProduct = `v3/search`;

//user
export const getMyOrderApi = `general/v1/productOrder`;

export const getInfiniteScrollData = (api: string, includeGeneral = true) => {
    return request({
        endpoint: api,
        includeGeneral: includeGeneral
    });
};

// s & shear & myIp
export const userTracking = (data: any) => {
    return request({
        endpoint: 'v1/userTrack/',
        method: 'POST',
        body: data
    });
};

export const getMyIp = () => {
    return request({
        endpoint: `v1/myIP`,
        includeGeneral: false,
        method: 'GET'
    });
};
